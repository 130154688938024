<template>
  <ul class="space-y-3 list-none">
    <brand-list-item
      v-for="(item, i) in items"
      :key="i"
      :list-item="item"
      :color="color"
    />
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'BrandList',
  props: {
    items: {
      type: Array,
      default: () => {}
    },
    color: {
      type: String,
      default: 'blue'
    }
  }
}
</script>
